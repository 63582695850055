.modal-dialog.video-player-modal {
  position: static;
  max-width: 90%;
  @include media-breakpoint-down(sm) {
    margin: 0 auto;
  }

  .modal-content {
    position: static;
    background: transparent;
    border: none;
  }

  .modal-body {
    overflow: hidden;
    padding: 0;
  }

  .btn-close {
    display: none;
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: fixed;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: .65;
    filter: alpha(opacity=65);
    padding: 0;
    color: #fff;
    font-style: normal;
    font-size: 28px;
    background: transparent;
  }
}

.modal-open {
  .modal-dialog.video-player-modal {
    .btn-close {
      display: block;
    }
  }
}


.modal-backdrop.video-player-modal-backdrop {
  background: #0b0b0b;
  opacity: .8;
}