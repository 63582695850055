body {
  padding-bottom: $footer-height;
}

h1.page-title {
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 0.6rem;
}

.header-wrapper {
  border-bottom: 1px solid $footer-bg-colour;

  .header {
    padding-top: 5px;
    padding-bottom: 5px;

    nav {
      min-height: 50px;

      &.nothing-completed {
        .progress-wrapper {
          color: $clr-disabled;

          .fmf-progress-bar {
            border-color: $clr-disabled;
          }
        }

        .tracker-wrapper {
          color: $clr-disabled;

          circle.circle {
            stroke: $clr-disabled !important;
          }

          line.line {
            stroke: $clr-disabled !important;
          }
        }

        .view-certificate-link {
          color: $clr-disabled;
        }
      }

      &.logged-out {
        .fmf-progress {
          display: none;

          @media (min-width: 400px) {
            display: inline-flex;
            align-items: center;
            margin-right: 0;
            margin-bottom: 0;
          }
        }

        ul.nav-links {
          display: block;

          @media (min-width: 400px) {
            display: inline;
          }

          li {
            @media (min-width: 400px) {
              margin-left: 20px;
            }
          }
        }

        span {
          @media (min-width: 400px) {
            text-align: right;
          }
        }
      }

      &.logged-in {
        .fmf-progress {
          display: none;

          @media (min-width: 485px) {
            display: inline-flex;
            align-items: center;
            margin-right: 0;
            margin-bottom: 4px;
          }
        }

        ul.nav-links {
          display: inline;
          vertical-align: middle;

          li:last-child {
            float: right;
          }

          @media (min-width: 485px) {
            vertical-align: baseline;

            li {
              margin-left: 20px;

              &:last-child {
                float: none;
              }
            }
          }
        }

        span {
          @media (min-width: 485px) {
            text-align: right;
          }
        }

        .user-email-wrapper {
          color: $clr-disabled;
          margin-right: 20px;
        }
      }
    }

    ul.nav-links {
      display: inline;
      list-style: none;
      text-align: right;
      margin: 0;
      padding: 0;

      li {
        display: inline;
        margin-right: 0;
      }
    }

    a.view-certificate-link {
      color: $body-color;
      text-decoration: none;
    }

    .tracker-image {
      display: inline-block;
      margin-left: 7px;

      svg {
        height: 15px;
        width: auto;
      }
    }
  }
}

.footer-wrapper {
  background: $footer-bg-colour;
}

.footer {
  height: $footer-height;

  .powered-by-modano {
    height: 25px;
  }

  @media (min-width: 380px) {
    .powered-by-modano {
      height: 30px;
    }
  }

  .tell-a-friend-btn {
    color: #6c757d;
    border-color: #6c757d;
    background-color: white;

    &:hover {
      background-color: $clr-disabled;
      border-color: $clr-disabled;
      color: white;
    }
  }
}