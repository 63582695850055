@import "sass/base";
@import "~bootstrap/scss/bootstrap";
@import "sass/layout";
@import "sass/theory";
@import "sass/practical";
@import "sass/video";
@import "sass/components";
@import "sass/FMFProgressBar";
@import "sass/ExamsList";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



.login-form,
.signup-form {
  max-width: 350px;
  margin: auto;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.modal-body {
  overflow: auto;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}





