.loading-overlay-dialog {
  .modal-body {
    text-align: center;
    padding-top: 2em;
    padding-bottom: 2em;

    .spinner-border {
      width: 8rem;
      height: 8rem;
      color: $clr-modano-orange;
    }

    p {
      margin-bottom: 1.5em;
    }
  }
}

.certification-image-loading {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  text-align: center;
}

.certification-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 70%;
}

.add-to-linked-in {
  margin-left: 7px;

  img {
    height: 35px;
  }
}

.certification-complete {
  .btn {
    margin-right: 1em;
    margin-bottom: 1em;
  }
}