.exam-theory-form {

  .exam-header {
    width: 100%;
    position: relative;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 0px;
    padding-bottom: 5px;

    .exam-subtitle {
      font-weight: bold;

      @include media-breakpoint-up(md) {
        float: left;
      }
    }

    .exam-info {
      padding-left: 0;
      margin: 0;

      @include media-breakpoint-up(md) {
        position: relative;
        float: right;
        padding-left: 2rem;
      }

      li {
        list-style-type: none;

        @media (min-width: 500px) {
          display: inline;
          padding: 0 7px 0 7px;
          border-left: 1px solid #ccc;
        }

        &:first-child {
          padding-left: 0;
          border-left: none;
        }

        .time-remaining-label {
          float: left;
          margin-right: 0.5em;
        }

        .time-remaining-countdown {
          font-weight: bold;

          div,
          span {
            display: inline;
            float: none !important;
          }
        }

      }
    }
  }

  .question-pager {
    padding-top: 2px;
    border-bottom: 1px solid #ccc;


    button {
      line-height: 30px;
      vertical-align: middle;
      padding: 1px 8px;
      border: 1px solid #888;
      color: #888;
      background: #fff;
      margin-right: 4px;
      min-width: 40px;
      box-sizing: border-box;
      border-radius: 2px;
      margin-bottom: 2px;

      &:last-child {
        margin-right: 0;
      }

      &.current {
        border: 1px solid $clr-modano-orange;
        background: $clr-modano-orange;
        color: #ffffff;

        &.answered {
          color: #ffffff;
        }
      }

      &:hover {
        border: 1px solid $clr-modano-orange;
        background: $clr-modano-orange;
        color: #ffffff;

        &.answered {
          color: #ffffff;
        }
      }

      &.answered {
        border: 1px solid $clr-modano-orange;
        color: $clr-modano-orange;
      }

    }
  }

  .question {
    padding: 10px 0;

    h3 {
      font-size: 1em;
      font-weight: bold;
    }

    .form-radios {
      margin-left: 15px;
      margin-top: 4px;
      margin-bottom: 4px;

      p {
        display: flex;
        align-items: flex-start;
      }

      label {
        margin-left: 10px;
      }

      input {
        flex: 0 0 12px;
        margin-top: 4px;
      }
    }

    .question-text {
      margin-bottom: 15px;
      width: 100%;

      p {
        margin: 0 1rem 1rem 0;
      }
    }

    &.question-image-right {
      .question-image {
        text-align: center;

        img {
          margin: 0 auto;
          display: block;
          max-width: 100%;
        }
      }

      @include media-breakpoint-up(md) {
        .question-markup {
          width: 50%;
          float: left;
        }

        .question-image {
          float: right;
          width: 50%;

          img {
            display: block;
            width: auto;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }

    &.question-image-below {
      .question-image {
        text-align: center;

        img {
          display: block;
          max-width: 100%;
          margin: 0 auto;
        }
      }

      .question-markup {
        margin-bottom: 40px;
      }
    }
  }
}

.theory-results-summary-table {
  border: 1px solid $clr-modano-orange;
  font-size: 80%;

  th,
  td {
    padding: 4px 8px 4px 8px;
  }

  th {
    background: $clr-modano-orange;
    color: #fff;
    border: 1px solid $clr-modano-orange;
  }

  td {
    border-bottom: 1px solid #ccc;
    vertical-align: top;

    &:first-child {
      border-left: 1px solid $clr-modano-orange;
    }

    &:last-child {
      border-right: 1px solid $clr-modano-orange;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  tr:last-child {
    td {
      border-bottom: 1px solid $clr-modano-orange;
    }
  }

  tr.correct {
    td {
      color: $clr-correct;
    }
  }

  tr.incorrect {
    td {
      color: $clr-incorrect;
    }
  }

}

.theory-exam-form {
  .modal-footer {
    @media (max-width: 387px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .missing-answers-warning {
      display: none;

      p {
        display: inline-block;
        margin: 0 1rem;
      }

      @media (min-width: 1000px) {
        display: block;
      }
    }

    .missing-answers-warning-small-text,
    .missing-answers-warning-small-button {
      display: block;

      @media (min-width: 1000px) {
        display: none;
      }
    }

    .missing-answers-warning-small-text {
      width: 100%;
      margin: 0 auto 12px auto;
      text-align: center;

      p {
        margin: 0;
      }
    }
  }
}