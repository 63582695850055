
.practical-feedback-table {
  border: 1px solid $clr-modano-orange;
  font-size: 80%;
  width: 100%;

  th, td {
    padding: 4px 8px 4px 8px;

    &.marks-col, &.score-col {
      text-align: center;
      width: 80px;
    }

    &.description-col {
      width: 500px;
      border-left: none;
    }
  }

  th {
    background: $clr-modano-orange;
    color: #fff;
    border: 1px solid $clr-modano-orange;
    border-right-color: #fff;

    &:last-child {
      border-right-color: $clr-modano-orange;
    }
  }

  td {
    vertical-align: top;
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-right: none;
    border-bottom: none;

    &.toggle-col {
      border-left: 1px solid $clr-modano-orange;
      width: 10px;
      text-align: center;
    }

    &.icon-col {
      width: 10px;
      text-align: left;
      vertical-align: top;
      border-right: none;
      padding-right: 0;
    }

    &:last-child {
      border-right: 1px solid $clr-modano-orange;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  tr.total-row {
    td {
      border-top: 1px solid $clr-modano-orange;
    }
  }

  tr:last-child {
    td {
      border-bottom: 1px solid $clr-modano-orange;
    }
  }

  tr.feedback-area {
    cursor: pointer;
  }

  tr.feedback-item {
    td {
      &:nth-child(3) {
        border-left: none;
      }
    }
  }



  tr.correct {
    td {
      color: $clr-correct;
    }
  }

  tr.incorrect {
    td {
      color: $clr-incorrect;
    }
  }

}