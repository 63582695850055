.fmf-progress {
  margin-right: auto;

  @include media-breakpoint-up(lg) {
    justify-content: center;
  }

  .fmf-progress-bar {
    display: inline-block;
    height: 20px;
    width: 100px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #000;

    >div {
      height: 100%;
      width: 0%;
      background: $clr-modano-orange;
      border-radius: 4px;
    }
  }

  .fmf-progress-label {
    margin-right: 5px;
    height: 20px;
    line-height: 20px;
    vertical-align: middle;

    @media (max-width: 485px) {
      vertical-align: top;
    }
  }
}