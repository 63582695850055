$clr-modano-orange: #b04425;
$clr-completed: #959595;
$clr-correct: #227447;
$clr-incorrect: #a52834;
$clr-disabled: #cccccc;

//Bootsrap overrides
$body-color: #57585C;
$primary: $clr-modano-orange;
$font-size-base: 0.9rem; //Override bootstrap default here
$footer-height: 60px;
$footer-bg-colour: #ebebeb;
$btn-white-space: nowrap;
