.exam-list {
  .row {
    margin-top: 0;

    >* {
      margin-top: 0;
      margin-bottom: 1.2rem;
    }
  }

  .intro-item {

    .bottom {
      display: flex;

      .btn {
        height: 36px;
        flex: 0 0 102px;
      }
    }

    .buttons {
      float: right;
      height: 100%;

      a {
        height: 24px;
        display: inline-block;
        margin-right: 15px !important;
        margin-top: 12px;

        &:first-child {
          margin-left: 15px;
        }
      }

      .svg-inline--fa {
        height: 100%;
      }
    }
  }

  .exam-item {
    @include media-breakpoint-up(md) {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .header {
      aspect-ratio: 1.777;
      position: relative;
      display: flex;
      align-items: center;
      text-decoration: none;
      background-color: $clr-modano-orange;

      &.video {
        background-color: transparent;
        background-image: url("../images/video_background_orange.svg");
        background-position: center center;
        background-size: cover;
      }

      h5 {
        margin-bottom: 0;
        text-align: center;
        width: 100%;
        color: #fff;
      }

      .video-duration {
        position: absolute;
        bottom: 5px;
        right: 5px;
        text-align: right;
        color: #fff;
      }
    }

    &.complete {
      .header {
        background-color: $clr-completed;

        &.video {
          background-color: transparent;
          background-image: url("../images/video_background_grey.svg");
          background-position: center center;
          background-size: cover;
        }
      }
    }

    .description {
      margin-top: 1em;
      margin-bottom: 0.7em;
      flex-grow: 1;

      :last-child {
        margin-bottom: 0;
      }
    }

    .scores {
      margin-bottom: 0.7em;

      span {
        margin-right: 10px;

        &.label {
          font-weight: bold;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .bottom {
      margin-bottom: 0;
      display: flex;
      align-items: end;

      .next-steps-button {
        flex: 0 0;
        color: $clr-modano-orange;
        border-color: $clr-modano-orange;

        &:hover {
          background: $clr-modano-orange;
          color: #ffffff;
        }
      }
    }

    .buttons {
      flex: 1;

      a {
        cursor: pointer;
        margin-right: 20px;
        color: $body-color;

        &:last-child {
          margin-right: 0 !important;
        }
      }
    }

    &.next-steps {
      .scores {
        margin-bottom: 4px;
      }
    }
  }
}